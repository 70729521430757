<template>
  <div class="blot" />
</template>

<script>
export default {
  name: "Blot"
};
</script>

<style lang="sass" scoped>
@import "../assets/styles/vars"

.blot
  width: 335px
  height: 335px
  border-radius: 50px
  background: #E0F0FD
</style>
