import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "ru",
  fallbackLocale: "ru",
  messages: {
    ru: {
      startButton: "Начать",
      mainTitle: "Создавай Приложения На Армянском",
      mainText:
        "Программа MIT App Inventor создана, чтобы научить тебя создавать мобильные Android-приложения. Будучи разработанной в США, программа переведена на армянский и теперь доступна для всех. На сайте ты найдешь подробные видео уроки, которые научат тебя работать с MIT App Inventor.",
      stages: [
        {
          title: "Образовательная",
          description: "Используется в ИТ-классах по всему миру."
        },
        {
          title: "Простая",
          description: "Собирайте приложения как пазлы."
        },
        {
          title: "На армянском",
          description: "Мы перевели всю программу для вас."
        }
      ],
      menuItems: ["О нас", "Команда", "Обучение"],
      programmerTitle: "Нажми на кнопку и становись программистом!",
      programmerText:
        "Мы хотим сделать онлайн-курс по App Inventor для школьников и методические материалы для учителей. Если вы преподаватель или хотите помочь нам как волонтер, пожалуйста, напишите на почту. А пока можно подписаться на наш YouTube-канал.",
      partners: "Партнеры",
      partnersTitle:
        "Хотите использовать MIT App Inventor в своей школе или предложить сотрудничество в другой сфере?",
      partnersSubtitle: "Ваш логотип может оказаться здесь!"
    },
    arm: {
      startButton: "Սկսել",
      mainTitle: "Ստեղծի՛ր հավելված հայերենով",
      mainText:
        "MIT App Inventor ծրագիրը թարգմանվել է հայերեն և հեշտությամբ կսովորեցնի քեզ մշակել բջջային Android հավելվածներ։ Մեր կայքէջում դու կգտնես ամեն ինչ՝ մանրամասն տեսադասեր և խորհուրդներ, որոնց միջոցով արագ կյուրացնես MIT App Inventor-ը։",
      stages: [
        {
          title: "Կրթական",
          description:
            "Օգտագործվում է IT-դասարաններում աշխարհի ցանկացած վայրում"
        },
        {
          title: "Պարզ",
          description: "Հավաքեք հավելվածներն ինչպես փազլը"
        },
        {
          title: "Հայերեն",
          description: "Ուշադրությունը մի կենտրոնացրեք անգլերենի վրա"
        }
      ],
      menuItems: ["Մեր մասին", "Թիմ", "Ուսուցանում"],
      programmerTitle: "Սեղմի՛ր կոճակը և դարձի՛ր ծրագրավորող։",
      programmerText:
        "Մենք ցանկանում ենք App Inventor-ի մասին առցանց դասընթաց ստեղծել աշակերտների և մեթոդաբանական նյութեր ուսուցիչների համար։ Եթե ուսուցիչ եք կամ ցանկանում եք օգնել մեզ որպես կամավոր, խնդրում ենք գրել մեր էլ․ փոստի հասցեին։ Իսկ մինչ այդ կարող եք բաժանորդագրվել մեր YouTube-ալիքին։",
      partners: "Գործընկերներ",
      partnersTitle:
        "Ցանկանո՞ւմ եք օգտագործել MIT App Inventor-ը Ձեր դպրոցում կամ ունե՞ք համագործակցության առաջարկ այլ ոլորտում։",
      partnersSubtitle: "Ձեր տարբերանշանը կարող է հայտնվել այստեղ։"
    }
  }
});
